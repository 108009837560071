import { Button, Popper } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Customization } from "./customization";
import { setCookiePreferences } from "./utils";
import { useSnackbar } from "../hooks";

const CookieBanner = () => {
  const [open, setOpen] = useState(!localStorage.getItem('is_google_analytics_accepted'));
  const [anchorEl] = useState<null | HTMLElement>(null);
  const [expanded, setExpanded] = useState<boolean>(false);
  const { showSnackbar } = useSnackbar();

  const confirmHandler = () => {
    setOpen(false);

    localStorage.setItem('is_google_analytics_accepted', 'true');
  }

  const onCustomizeConfirm = (values: string[]) => {
    setCookiePreferences(values);
    showSnackbar({
      type: 'success',
      message: 'Your preferences have been saved'
    });
    setOpen(false);
  }

  const toggleCustomization = () => {
    setExpanded(val => !val);
  }

  return (
    <Popper id={'cookie-banner'} open={open} anchorEl={anchorEl} className="fixed !top-auto sm:!left-auto !left-6 bottom-4 right-6 z-50 bg-white sm:w-[450px] p-4 rounded max-h-[80vh] overflow-auto shadow-lg">
      <div className="text-sm mb-4">
        <h3 className="text-base font-medium mb-1">About Your Privacy</h3>
        Like most sites, we use cookies to give you the best online experience. 
        Cookies keep our site secure and reliable. Learn more from our <Link to="/cookie-policy" className="text-link">Cookie Policy</Link>. You can
        customize the use of cookies anytime.
      </div>
      <div className="text-right">
        <Button variant="text" onClick={toggleCustomization} className="!normal-case">Customize</Button>
        <Button variant="outlined" onClick={confirmHandler} className="!bg-lightBlue !ml-3 !normal-case">
          Allow cookies
        </Button>
      </div>
      <Customization isExpanded={expanded} onConfirm={onCustomizeConfirm} />
    </Popper>
  );
}

export default CookieBanner;
