import React, { useContext, useState } from "react";

export type SnackbarContextType = {
  open: boolean;
  message?: string | null;
  type?: 'success' | 'info' | 'warning' | 'error';
  change: (values: Omit<SnackbarContextType, 'change'>) => void;
};

const SnackbarContext = React.createContext<SnackbarContextType>({ 
  open: false,
  message: null,
  type: 'success',
  change: () => {}
});

export const useSnackbarContext = () =>
  useContext(SnackbarContext);

interface Props {
  children: React.ReactNode;
}

const SnackbarContextProvider = ({ children }: Props) => {
  const [snackbar, setSnackbar] = useState<Omit<SnackbarContextType, 'change'>>({
    open: false,
    message: null,
    type: 'success'
  });
  
  return (
    <SnackbarContext.Provider value={{
      ...snackbar,
      change: setSnackbar
    }}>
      {children}
    </SnackbarContext.Provider>
  );
}

export default SnackbarContextProvider;
