import { IconButton } from '@mui/material';
import { FavoriteIcon, FovoriteFillIcon, ShippingIcon, VisibilityIcon, WarehouseIcon } from '../icons';
import { useFavoriteContext } from '../../app/context/FavoriteContext';
import { useEffect, useMemo, useState } from 'react';
import DOMPurify from 'dompurify';
import { IKImage } from 'imagekitio-react';
import { round } from 'lodash';

interface IProps {
  product: {
    _id: string;
    name: string;
    description: string;
    price: number;
    oldPrice: number;
    originImageUrl: string;
    imageUrl: string;
    producer: string;
    size: string;
    storeName: string;
    url: string;
    quantity: number;
    views: number;
    isFreeDelivery: boolean;
    highlights: Array<{
      path: string;
      score: number;
      texts: Array<{
        type: 'hit' | 'text';
        value: string;
      }>
    }>;
    colors?: string[];
    sizes?: string[];
  }
}

const Product = ({ product }: IProps) => {
  const favoriteContext = useFavoriteContext();
  const descriptionHighlights = product?.highlights?.find(item => item.path === 'description') || { texts: [] };
  
  const [favorite, setFavorite] = useState(false);

  let name = DOMPurify.sanitize(product.name);
  let description = DOMPurify.sanitize(product.description);

  descriptionHighlights?.texts?.forEach(item => {
    if (item.type === 'hit') {
      description = description.replace(item.value, `<span class="font-bold text-gray">${item.value}</span>`);
    }
  });

  useEffect(() => {
    setFavorite(favoriteContext.items?.includes(product._id));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favoriteContext.items]);

  const addToFavorite = (event: any) => {
    event.preventDefault();

    if (favorite) {
      favoriteContext.remove(product._id);
    } else {
      favoriteContext.add(product._id);
    }
  }

  const promo = useMemo(() => {
    if (product.oldPrice) {
      return round(100*(Number(product.price) - Number(product.oldPrice))/Number(product.price));
    }

    return 0;
  }, [product]);

  const colors = useMemo(() => {
    const maxDisplayedColors = 7;

    if (!!product?.colors?.length) {
      const colorsToDisplay = product.colors.slice(0, maxDisplayedColors-1);
      const restColors = product.colors.length - colorsToDisplay.length;
      return {
        list: colorsToDisplay,
        rest: restColors > 0 ? restColors : 0
      };
    } else {
      return {
        list: [],
        rest: 0
      }
    }
  }, [product]);

  return (
      <a
        href={`/redirection/${product._id}`}
        target='_blank'
        rel="noreferrer"
      >
        <div className="relative">
          <div className="absolute top-0 w-full z-10 flex justify-between p-1">
            <div>
              {product.views >= 100 && (
                <div className="flex items-center text-xs text-gray bg-white rounded-full px-2">
                  <VisibilityIcon className='fill-gray h-7 w-7' />
                  <span className='ml-1'>{product.views || 0}</span>
                </div>
              )}
            </div>
            {!!promo && (
              <span className="flex items-center text-xs bg-red text-white px-2 rounded-full min-h-7">{promo}%</span>
            )}
          </div>

          <div className="relative">
            <div className="absolute bottom-1 right-1 z-10 rounded-full bg-white">
              <IconButton onClick={addToFavorite}>
                {favorite ? <FovoriteFillIcon className='w-6 h-6 fill-red' /> : <FavoriteIcon className='w-6 h-6 fill-red' />}
              </IconButton>
            </div>
            <div className="relative w-full pb-[100%]">
              <div className="absolute h-full w-full flex items-center justify-center">
                {product.imageUrl ? (
                  <ImageKitImage
                    url={product.imageUrl}
                    name={product.name}
                  />
                ) : (
                  <img
                    src={product.originImageUrl}
                    alt={product.name}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="mt-4 mb-6">
            <h3 className="line-clamp-3 text-sm text-black font-medium">{name}</h3>

            <div className="flex items-end mt-1 mb-2">
              <div className="text-black font-medium text-2xl leading-none">
                ${String(round(product.price, 2).toFixed(2))}
              </div>
              
              {product.oldPrice && (
                <div className="ml-1 text-gray text-sm font-normal decoration line-through">
                  ${String(round(product.oldPrice, 2).toFixed(2))}
                </div>
              )}
            </div>

            {!!colors.list.length && (
              <div className='flex flex-wrap items-center gap-1 my-1'>
                {colors.list.map(color => (
                  <div key={color} className='w-4 h-4 rounded-full' style={{ backgroundColor: color }} />
                ))}
                {!!colors.rest && <div className='text-xs'>+{colors.rest}</div>}
              </div>
            )}

            {product?.sizes?.length && (
              <div className='my-1 text-xs'>
                {product?.sizes?.length === 1 ? (
                  <div>Size: <span>{product.sizes[0]}</span></div>
                ) : (
                  `${product.sizes.length} different sizes`
                )}
              </div>
            )}

            {product?.isFreeDelivery && (
              <div className="flex items-center text-xs text-blue">
                <ShippingIcon className='w-6 h-6 fill-blue' />
                <span className='ml-1'>Free delivery</span>
              </div>
            )}

            <h4 className="line-clamp-[9] text-xs text-gray my-1" dangerouslySetInnerHTML={{ __html: description }} />

            {product?.quantity <= 3 && (
              <div className="flex items-center text-xs text-red font-normal">
                <WarehouseIcon className='w-6 h-6 fill-red' />
                <span className='ml-1'>Only {product.quantity} left in stock</span>
              </div>
            )}
          </div>
        </div>
      </a>
  );
};

const ImageKitImage = ({ url, name }: { url: string; name: string }) => {
  const fileName = url.split('https://ik.imagekit.io/o23ci9nx7');

  return (
    <IKImage
      path={fileName[1]}
      loading="lazy"
      alt={name}
      lqip={{
        active: true,
        quality: 20,
        blur: 10
      }}
    />
  );
}

export default Product;
