import React from "react";

const MainPage = React.lazy(() => import('../../modules/main/MainPage'));

const MainConfig = {
  routes: [
    {
      path: '/',
      element: <MainPage />
    }
  ]
}

export default MainConfig;
