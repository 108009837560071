import React from "react";

const PricingPage = React.lazy(() => import('../../modules/pricing/PricingPage'));

const PricingConfig = {
  routes: [
    {
      path: '/pricing',
      element: <PricingPage />
    }
  ]
}

export default PricingConfig;
