import { Button, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import cn from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

type Props = {
  position?: 'TOP_BAR' | 'MAIN_PAGE';
}

const ProductsSearchBar = ({ position = 'TOP_BAR' }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);

  const form = useForm({
    resolver: yupResolver(Yup.object({
      query: Yup.string().required().min(2)
    })),
    defaultValues: {
      query: queryParameters.get('query') || ''
    }
  });

  const onSubmit = (values: { query: string }) => {
    navigate(`/products?query=${values.query}`, { state: {} });
  }

  const onKeydown = (event: any) => {
    if (event.code === 'Enter') {
      form.handleSubmit(onSubmit)();
    }
  }

  return (
    <div className={cn(
      'inline-flex items-center py-[2px] px-1 border-gray bg-white w-full',
      position === 'MAIN_PAGE' && 'border rounded-3xl',
      position === 'TOP_BAR' && 'border-t border-b md:border md:rounded-3xl' 
     )}>
      <IconButton type="button" disabled>
        <SearchIcon className='!fill-blue' />
      </IconButton>
      <FormProvider {...form}>
        <input
          type='text'
          className="w-full border-0 outline-none text-sm pl-2 border-l border-gray"
          placeholder='Search products'
          maxLength={70}
          onKeyDown={onKeydown}
          {...form.register('query')}
        />
        <Button
          size="small"
          variant="outlined"
          onClick={form.handleSubmit(onSubmit)}
          classes={{
            root: '!normal-case !rounded-3xl !mr-1 !bg-lightBlue'
          }}
        >
          Search
        </Button>

      </FormProvider>
    </div>
  );
}

export default ProductsSearchBar;
