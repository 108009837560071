import API from "../endpoints"
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

const getProducts = async (params: any) => {
  const { data } = await API.findProducts(params);

  return data;
}

const useProductsQuery = (params: any, queryConfig: Omit<UseQueryOptions<any, unknown, any, QueryKey>, "queryKey" | "queryFn"> = {}) => {
  return useQuery<any>(['PRODUCT_CAROUSEL', params], () => getProducts(params), queryConfig);
};

export default useProductsQuery;
