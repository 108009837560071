import { Controller, useFormContext } from "react-hook-form";
import styles from './CheckboxField.module.scss';
import { DoneIcon } from "../../icons";
import { ErrorMessage } from "../error-message";

type Props = {
  name: string;
  title?: string | React.ReactNode;
  isDisabled?: boolean;
}

const CheckboxField = ({ name, title, isDisabled }: Props) => {
  const { control } = useFormContext();
  
  return (
    <div className={styles.root}>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <div>
              <label className={styles.inner}>
                <input
                  type="checkbox"
                  value={value}
                  checked={value}
                  onChange={(event) => onChange(event.target.checked)}
                  className={styles.field}
                  disabled={isDisabled}
                />
                <div className={styles.styledCheckbox}>
                  <DoneIcon />
                </div>
                {!!title && <div className={styles.content}>
                  {title}
                </div>}
              </label>
              {error?.message && <div className="pl-5"><ErrorMessage message={error.message} /></div>}
            </div>
          )
        }}
      />
    </div>
  );
}

export default CheckboxField;
