import { useSnackbarContext } from "../../app/context/SnackbarContext";

const useSnackbar = () => {
  const { change } = useSnackbarContext();

  const showSnackbar = ({ message, type }: { message: string; type: 'success' | 'info' | 'warning' | 'error'; }) => {
    change({
      open: true,
      message,
      type
    });
  }

  return {
    showSnackbar
  }
}

export default useSnackbar;
