import React, { useContext, useEffect, useState } from 'react';
import { LocalStorageNameKeys } from '../../common/models/common.models';

export type FavoriteContextType = {
  items: string[];
  add: (productId: string) => void;
  remove: (productId: string) => void;
  clear: () => void;
};

const FavoriteContext = React.createContext<FavoriteContextType>({
  items: [],
  add: () => { },
  remove: () => { },
  clear: () => { }
});

export const useFavoriteContext = () =>
  useContext(FavoriteContext);

interface IProps {
  children: React.ReactNode;
}

const FavoriteContextProvider = ({ children }: IProps) => {
  const [favorites, setFavorites] = useState<string[]>([]);

  useEffect(() => {
    const favorites = JSON.parse(localStorage.getItem(LocalStorageNameKeys.FAVORITES) ?? '[]');

    if (!favorites) {
      localStorage.setItem(LocalStorageNameKeys.FAVORITES, "[]");
    }

    setFavorites(favorites);
  }, []);

  const addProductToFavoriteList = (productId: string) => {
    const favoriteProducts = JSON.parse(localStorage.getItem(LocalStorageNameKeys.FAVORITES) ?? '[]');
    favoriteProducts.push(productId);

    localStorage.setItem(LocalStorageNameKeys.FAVORITES, JSON.stringify(favoriteProducts));
    setFavorites(favoriteProducts);
  }

  const removeProductFromFavoriteList = (productId: string) => {
    const favorites = JSON.parse(localStorage.getItem(LocalStorageNameKeys.FAVORITES) ?? '[]');
    const favoriteProducts = favorites.filter((item: string) => item !== productId);

    localStorage.setItem(LocalStorageNameKeys.FAVORITES, JSON.stringify(favoriteProducts));
    setFavorites(favoriteProducts);
  }

  const clearFavoriteList = () => {
    localStorage.setItem(LocalStorageNameKeys.FAVORITES, JSON.stringify([]));

    setFavorites([]);
  }

  return (
    <FavoriteContext.Provider value={{
      items: favorites,
      add: addProductToFavoriteList,
      remove: removeProductFromFavoriteList,
      clear: clearFavoriteList
    }}>
      {children}
    </FavoriteContext.Provider>
  );
}

export default FavoriteContextProvider;
