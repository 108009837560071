import { createContext, useContext, useMemo } from "react";
import routes from "../config/routes.config";

type AppContextType = {
  routes: Array<any>;
};

const AppContext = createContext<AppContextType>({
  routes: []
});

export const useAppContext = () => useContext(AppContext);

type Props = {
  children: React.ReactNode
}

const AppContextProvider = ({ children }: Props) => {
  const routesMemoized = useMemo(() => ({ routes }), []);

  return (
    <AppContext.Provider value={routesMemoized}>
      {children}
    </AppContext.Provider>
  );
}

export default AppContextProvider;
