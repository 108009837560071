import React from "react";

const CookiePolicyPage = React.lazy(() => import('../../modules/text-pages/CookiePolicy'));
const PrivacyPolicyPage = React.lazy(() => import('../../modules/text-pages/PrivacyPolicy'));
const CaliforniaPrivacyDisclosurePage = React.lazy(() => import('../../modules/text-pages/CaliforniaPrivacyDisclosure'));
const AccessibilityPage = React.lazy(() => import('../../modules/text-pages/Accessibility'));
const DoNotSellMyPersonalInformationPage = React.lazy(() => import('../../modules/text-pages/DoNotSellMyPersonalInformation'));
const TermsAndConditionsPage = React.lazy(() => import('../../modules/text-pages/TermsAndConditions'));
const FaqPage = React.lazy(() => import('../../modules/text-pages/Faq'));
const AdvertisingPage = React.lazy(() => import('../../modules/text-pages/Advertising'));
const HowItWorksPage = React.lazy(() => import('../../modules/text-pages/HowItWorks'));

const TextPagesConfig = {
  routes: [
    {
      path: '/cookie-policy',
      element: <CookiePolicyPage />
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicyPage />
    },
    {
      path: '/california-privacy-disclosure',
      element: <CaliforniaPrivacyDisclosurePage />
    },
    {
      path: '/accessibility',
      element: <AccessibilityPage />
    },
    {
      path: '/do-not-sell-my-personal-information',
      element: <DoNotSellMyPersonalInformationPage />
    },
    {
      path: '/terms-and-conditions',
      element: <TermsAndConditionsPage />
    },
    {
      path: '/faq',
      element: <FaqPage />
    },
    {
      path: '/advertising',
      element: <AdvertisingPage />
    },
    {
      path: '/how-it-works',
      element: <HowItWorksPage />
    }
  ]
}

export default TextPagesConfig;
