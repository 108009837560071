import { useEffect, useState } from "react";
import { CheckboxField } from "../checkbox-field";
import styles from './CheckboxGroup.module.scss';

type Props = {
  name: string;
  options: { title: string, value: string, count: number }[];
  isDisabled?: boolean;
  maxExpaned?: number;
}

const CheckboxGroup = ({ name, options, isDisabled, maxExpaned = 10 }: Props) => {
  const [data, setData] = useState<{ title: string, value: string, count: number }[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setData(options.filter((item, index) => index < maxExpaned));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const expandHandler = () => {
    if (isExpanded) {
      setData(options.filter((item, index) => index < maxExpaned));
    } else {
      setData(options);
    }

    setIsExpanded(expanded => !expanded);
  }

  return (
    <div className={styles.root}>
      <div className={styles.checkboxes}>
        {data.map((option, index: number) =>
          <div className={styles.input} key={option.value}>
            <CheckboxField name={option.value} title={option.title} isDisabled={isDisabled} />
            <span className={styles.count}>{option.count}</span>
          </div>
        )}
      </div>
      {maxExpaned < options.length && (
        <div className={styles.showMore}>
          <button className="text-xs" onClick={expandHandler}>{isExpanded ? 'Show less' : 'Show more'}</button>
        </div>
      )}
    </div>
  );
}

export default CheckboxGroup;
