import { useSnackbarContext } from "../../app/context/SnackbarContext";
import { Alert, Slide, SlideProps, Snackbar as SnackbarMui } from "@mui/material";

type TransitionProps = Omit<SlideProps, 'direction'>;

const TransitionLeft = (props: TransitionProps) => {
  return <Slide {...props} direction="left" />;
}

const Snackbar = () => {
  const { open, message, type, change } = useSnackbarContext();

  const handleClose = () => {
    change({
      open: false,
    })
  }

  return (
    <SnackbarMui
      open={open}
      TransitionComponent={TransitionLeft}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </SnackbarMui>
  );
}

export default Snackbar;
