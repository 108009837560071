import React from "react";

const CategoryPage = React.lazy(() => import('../../modules/category/CategoryPage'));

const CategoryConfig = {
  routes: [
    {
      path: '/products',
      element: <CategoryPage />
    }
  ]
}

export default CategoryConfig;
