import { FavoriteBorder } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from './RightSide.module.scss';
import { useFavoriteContext } from "../../../../app/context/FavoriteContext";

const RightSide = () => {
  const navigate = useNavigate();
  const favoriteContext = useFavoriteContext();

  return (
    <div className={styles.root}>
      <Button
        size="small"
        variant="text"
        startIcon={<FavoriteBorder />}
        color="primary"
        onClick={() => navigate('/wishlist')}
        classes={{
          root: styles.wishlistBtnRoot,
          startIcon: favoriteContext.items.length ? styles.wishlistIconActive : ''
        }}
      >
        <span className={styles.text}>Wishlist</span>
        {!!favoriteContext.items.length && <div className={styles.wishlistTag}>{favoriteContext.items.length}</div>}
      </Button>
    </div>
  );
}

export default RightSide;
