import MainConfig from './main.config';
import WishlistConfig from './wishlist.config';
import RegistrationConfig from './registration.config';
import CategoryConfig from './category.config';
import RedirectionConfig from './redirection.config';
import PricingConfig from './pricing.config';
import TextPagesConfig from './textPages.config';

const routesConfig = [
  MainConfig,
  WishlistConfig,
  RegistrationConfig,
  CategoryConfig,
  RedirectionConfig,
  PricingConfig,
  TextPagesConfig
];

export default routesConfig;
