import React from "react";

const RedirectionPage = React.lazy(() => import('../../modules/redirection/RedirectionPage'));

const RedirectionConfig = {
  routes: [
    {
      path: '/redirection/:productId',
      element: <RedirectionPage />
    }
  ]
}

export default RedirectionConfig;
