import PaymentIcon from '@mui/icons-material/Payment';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ForkRightIcon from '@mui/icons-material/ForkRight';

const data = {
  data: [
    {
      title: "Payment and invoice",
      Icon: PaymentIcon,
      questions: [
        {
          title: 'What payment methods do you accept?',
          answer: <div>
            In our online store, we accept a variety of payment methods to ensure 
            convenience and flexibility for our customers. Here is a list of accepted payment methods:

            <p>
              <strong>Credit Cards:</strong> We accept payments with Visa, MasterCard, and American Express credit cards. 
              You can confidently use these cards to make payments for your orders.
            </p>
            <p>
              <strong>PayPal:</strong> If you prefer payments through the PayPal platform, we are integrated with it. With 
              PayPal, you can make payments using your PayPal account or credit cards.
            </p>
            <p>
              <strong>Bank Transfers:</strong> We also offer the option of payment via bank transfer. After placing your order, 
              you will receive the necessary banking information to complete the transfer.
            </p>
          </div>
        },
        {
          title: 'Are my payment details secure?',
          answer: <div>
            The security of your payment data is our top priority. Our website is secured with the latest SSL (Secure Socket Layer) 
            technology, which means that all information transmitted during payments is encrypted to protect it from unauthorized access. 
            Additionally, we do not store any payment data on our servers, further enhancing security.
          </div>
        },
        {
          title: 'Do you accept installment payments?',
          answer: <div>
            Yes, we offer customers the option of installment payments for selected products. This option allows you to spread the cost 
            of your purchase into convenient installments, making it easier to afford higher-value items. Details regarding the availability 
            of installment payments can be found on the product page when placing your order.
          </div>
        },
        {
          title: 'When will my order be charged?',
          answer: <div>
            The payment for your order will be charged immediately upon placing the order. If you have chosen to pay by credit card or via PayPal, 
            the amount will be processed right away. For bank transfers, please note that the time it takes for the payment to be credited may vary 
            depending on your bank. We recommend monitoring your bank account for updates.
          </div>
        },
        {
          title: 'Do you offer payment refunds?',
          answer: <div>
            Yes, we are open to payment refunds in accordance with our return policy. If, for any reason, you are not satisfied with your purchase, 
            you have the right to return the product and receive a payment refund. Please review our return policy on our website for more information. 
            We are ready to assist with the return process if you have any questions.
          </div>
        }
      ]
    },
    {
      title: "How does it work?",
      Icon: ForkRightIcon,
      questions: [
        {
          title: 'Do you offer payment refunds?',
          answer: <div>
            Yes, we are open to payment refunds in accordance with our return policy. If, for any reason, you are not satisfied with your purchase, 
            you have the right to return the product and receive a payment refund. Please review our return policy on our website for more information. 
            We are ready to assist with the return process if you have any questions.
          </div>
        }
      ]
    },
    {
      title: "Other questions",
      Icon: HelpOutlineIcon,
      questions: [
        {
          title: 'When will my order be charged?',
          answer: <div>
            The payment for your order will be charged immediately upon placing the order. If you have chosen to pay by credit card or via PayPal, 
            the amount will be processed right away. For bank transfers, please note that the time it takes for the payment to be credited may vary 
            depending on your bank. We recommend monitoring your bank account for updates.
          </div>
        }
      ]
    }
  ]
}

export default data;