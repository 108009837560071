import * as Yup from 'yup';

export const initializeYup = (t: (key: string) => string) => {
  Yup.setLocale({
    mixed: {
      required: t('common:validation.required')
    },
    string: {
      min: t('common:validation.tooShort'),
      max: t('common:validation.tooLong'),
      email: t('common:validation.email'),
      url: t('common:validation.url')
    }
  })
}
