import styles from './ProductCarousel.module.scss';
import { Product, Text } from "../../common";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, SwiperOptions } from 'swiper';
import { useProductsQuery } from './api';
import { useRef } from 'react';
import { Button, IconButton } from '@mui/material';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import { useNavigate } from "react-router-dom";

type Props = {
  title: string;
  filters: Object;
  breakpoints?: SwiperOptions['breakpoints'];
}

const ProductCarousel = ({ title, filters, breakpoints }: Props) => {
  const navigate = useNavigate();
  const { data } = useProductsQuery({ filters });

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const showMoreClickHandler = () => {
    navigate(`/products`, { state: { filters } });
  }

  return (
    <div className={styles.root}>
      <Text type="display2" htmlTag="h2">
        <div className={styles.titleRoot}>
          <div className={styles.left}>
            {title}
            <div className={styles.navigation}>
              <div ref={navigationPrevRef}>
                <IconButton>
                  <ChevronLeftOutlinedIcon fontSize="medium" />
                </IconButton>
              </div>
              <div ref={navigationNextRef}>
                <IconButton>
                  <ChevronRightOutlinedIcon fontSize="medium" />
                </IconButton>
              </div>
            </div>
          </div>
          <div className={styles.showMore}>
            <Button
              onClick={showMoreClickHandler}
              size="small"
              variant="text"
              color="primary"
            >
              Show more
            </Button>
          </div>
        </div>
      </Text>

      {data && (
        <Swiper
          spaceBetween={10}
          slidesPerView={7}
          breakpoints={breakpoints || {
            1600: {
              slidesPerView: 7
            },
            1400: {
              slidesPerView: 6
            },
            1201: {
              slidesPerView: 5
            },
            900: {
              slidesPerView: 4
            },
            600: {
              slidesPerView: 3
            },
            0: {
              slidesPerView: 2,
              spaceBetween: 10
            }
          }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: true
          }}
          modules={[Autoplay, Navigation]}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current
          }}
          onBeforeInit={(swiper: any) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
          }}
        >
          {(data?.items || []).map((product: any) => (
            <SwiperSlide key={product._id}>
              <Product product={product} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
}

export default ProductCarousel;
