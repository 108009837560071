import { CookieBanner, Footer, Snackbar, TopBar } from "../../../common";

type Props = {
  children: React.ReactNode;
}

const MainLayout = ({ children }: Props) => {
  return (
    <div>
      <TopBar />
      {children}

      <CookieBanner />
      <Footer />
      <Snackbar />
    </div>
  );
}

export default MainLayout;
