import { Controller, useFormContext } from 'react-hook-form';
import { Label } from '../label';
import styles from './TextField.module.scss';
import { ErrorMessage } from '../error-message';
import cn from 'classnames';

type Props = {
  name: string;
  label: string;
  placeholder?: string;
  inputClassName?: string;
};

const TextField = ({ name, label, placeholder, inputClassName }: Props) => {
  const { control } = useFormContext();

  return (
    <div className={styles.root}>
      <Label text={label} />

      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <div>
            <input type="text" className={cn(styles.field, inputClassName)} value={value} onChange={onChange} placeholder={placeholder} />
            {error?.message && <ErrorMessage message={error.message} />}
          </div>
        )}
      />
    </div>
  );
}

export default TextField;
