import React from "react";

const RegistrationPage = React.lazy(() => import('../../modules/registration/pages/registration/RegistrationPage'));

const RegistrationConfig = {
  routes: [
    {
      path: '/sign-up',
      element: <RegistrationPage />
    }
  ]
}

export default RegistrationConfig;
