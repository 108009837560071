import { useLocation } from 'react-router-dom';
import styles from './TopBar.module.scss';
import { LeftSide, Middle, RightSide } from './components';
import { useLayoutEffect, useMemo, useState } from 'react';
import cn from 'classnames';

const TopBar = () => {
  const location = useLocation();
  const [position, setPosition] = useState(0);

  const isMainPage = useMemo(() => location.pathname === '/', [location]);

  useLayoutEffect(() => {
    if (!isMainPage) {
      return;
    }

    function updatePosition() {
      setPosition(window.scrollY);
    }
    window.addEventListener('scroll', updatePosition);
    updatePosition();
    return () => window.removeEventListener('scroll', updatePosition);
  }, [isMainPage]);

  return (
    <div className={cn(styles.root, isMainPage && position > 100 && styles.mainBorderBottom, !isMainPage && styles.borderBottom)}>
      <div className={styles.content}>
        <div className={styles.left}>
          {(!isMainPage || (isMainPage && position > 100)) && <LeftSide />}
        </div>
        <div className={styles.center}>
          {!isMainPage && <Middle />}
        </div>
        <div className={styles.right}>
          <RightSide />
        </div>
      </div>
    </div>
  );
}

export default TopBar;
