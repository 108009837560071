import styles from './Label.module.scss';

type Props = {
  text: string;
};

const Label = ({ text }: Props) => {
  return (
    <div className={styles.root}>
      {text}
    </div>
  )
}

export default Label;
