import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { Text, TextField } from "../";
import { FacebookOutlined, Instagram } from '@mui/icons-material';
import { Button } from '@mui/material';
import { FavoriteIcon, ClinicalNotesIcon, HandshakeIcon } from '../icons';
import { useSubscribeUserMutation } from './api';
import { useSnackbar } from '../hooks';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useCallback } from 'react';

const Footer = () => (
  <div className="mt-12 bg-lightBlue">
    <Top />
    <Middle />
    <Bottom />
  </div>
);

const Top = () => {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { mutate: subscribeUser } = useSubscribeUserMutation();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    return await executeRecaptcha('Subscription');
  }, [executeRecaptcha]);

  const form = useForm<any>({
    mode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        email: Yup.string().required().email().min(8).max(255),
      })
    ),
    defaultValues: {
      email: ''
    }
  });

  const onSubmit = async (values: any) => {
    const token = await handleReCaptchaVerify();

    if (token) {
      subscribeUser({
        email: values.email,
        token
      }, {
        onSuccess: () => {
          form.reset();
  
          showSnackbar({
            message: t('common:messages.subscribedSuccess'),
            type: 'success'
          });
        },
        onError: (error: any) => {
          showSnackbar({
            message: error.response.data.message,
            type: 'error'
          });
        }
      });
    }
  };

  return (
    <div className="py-5 border border-lightGray" style={{
      backgroundImage: 'repeating-linear-gradient(45deg, #f7f7f7 0, #00000001 1px, #ffffff 0, #ffffff 50%)',
      backgroundSize: '10px 10px'
    }}>
      <div className="container">
        <div className="w-full md:w-1/2 text-left">
          <div className='text-darkGray text-xs mb-2 font-medium'>
            Stay in the know! Sign up for our newsletter to receive exclusive offers, event updates, 
            and insider content directly to your inbox. Don't miss out—subscribe now for all the latest news delivered right to you!
          </div>
          <div className='relative w-full sm:w-96'>
            <FormProvider {...form}>
              <TextField name="email" label="" placeholder="Provide your e-mail" inputClassName='!rounded-3xl !pr-[100px] text-sm' />

              <span className="absolute top-[5px] right-2">
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={form.handleSubmit(onSubmit)}
                  className='!normal-case !rounded-3xl !bg-lightBlue'
                >
                  Subscribe
                </Button>
              </span>
            </FormProvider>
          </div>
        </div>
      </div>
    </div>
  );
}
const Middle = () => {
  const onSocialClick = (type: 'fb' | 'insta') => {
    console.log(type);
  };

  return (
    <div>
      <div className="container">
        <div className="flex flex-wrap justify-between my-6">
          <div className="flex flex-wrap w-full lg:w-[65%]">
            <ListWrapper title="Company" Icon={FavoriteIcon}>
              <Link to={'/about-us'}>About us</Link>
            </ListWrapper>

            <ListWrapper title="Legal" Icon={ClinicalNotesIcon}>
              <Link to={'/accessibility'}>Accessibility</Link>
              <Link to={'/privacy-policy'}>Privacy Policy</Link>
              <Link to={'/california-privacy-disclosure'}>California Privacy Disclosure</Link>
              <Link to={'/cookie-policy'}>Cookie Policy</Link>
              <Link to={'/do-not-sell-my-personal-information'}>Do not sell my personal information</Link>
              <Link to={'/terms-and-conditions'}>Terms and conditions</Link>
            </ListWrapper>

            <ListWrapper title="For Merchants" Icon={HandshakeIcon}>
              <Link to={'/how-it-works'}>How it works</Link>
              <Link to={'/sign-up'}>Sign up</Link>
              <Link to={'/pricing'}>Pricing</Link>
              <Link to={'/faq'}>FAQ</Link>
              <Link to={'/advertising'}>Place advertising</Link>
            </ListWrapper>
          </div>
          <div className="w-full lg:w-[35%]">
            <Text type="display4" className='!pt-0 !text-darkGray'>
              If you have any questions or need additional information, we are here to help! You can contact us by sending an email
              to <span className='text-link'>office@atlassian.com</span>. Additionally, you can find us on selected social media platforms where we share the latest updates
              and answers to frequently asked questions. Don't hesitate to ask anything - we are ready to provide support and address your inquiries.
            </Text>
            <Button onClick={() => onSocialClick('fb')} startIcon={<FacebookOutlined className='!fill-gray' />} className='!normal-case !text-gray'>Facebook</Button>
            <Button onClick={() => onSocialClick('insta')} startIcon={<Instagram className='!fill-gray' />} className='!normal-case !text-gray'>Instagram</Button>
          </div>
        </div>
      </div>
    </div>
  );
}
const Bottom = () => (
  <div className="bg-white border-t-[1px] border-lightGray text-sm">
    <div className="container">
      <div className="flex flex-col sm:flex-row justify-between py-4">
        <div>Using the website means accepting the <Link to={'/terms'} className='text-link'>rules</Link>.</div>
        <div>© {new Date().getFullYear()} Atlassian</div>
      </div>
    </div>
  </div>
);

const ListWrapper = ({ title, Icon, children }: any) => (
  <div className="w-1/2 sm:w-1/3 mb-6 pr-2">
    <div className="flex items-center text-darkGray text-base font-semibold mb-4 mt-0">
      <Icon className='h-6 w-6 fill-blue mr-2' />
      {title}
    </div>

    <div className="[&_a]:text-sm [&_a]:text-darkGray [&_a]:font-medium [&_a]:block [&_a]:mb-1 [&_a:hover]:underline">
      {children}
    </div>
  </div>
);

export default Footer;
