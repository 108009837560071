import styles from './Text.module.scss';
import cn from 'classnames';

type Props = {
  type: string;
  htmlTag?: string;
  children: React.ReactNode;
  className?: string;
}

const Text = ({ type, htmlTag = 'p', children, className }: Props) => {
  const CustomTag = htmlTag as keyof JSX.IntrinsicElements;

  return (
    <CustomTag className={cn(styles.root, styles[type], className)}>
      {children}
    </CustomTag>
  )
}

export default Text;
