import { Accordion, AccordionDetails, AccordionSummary, Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import faqData from './faq.data';

const Faq = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
    >
      <div>
        <div className="w-80 p-5 rounded-md bg-lightBlue2">
          <Tabs
            orientation="vertical"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            classes={{
              flexContainer: 'items-start',
              indicator: 'hidden'
            }}
          >
            {faqData.data.map(({ title, Icon }) => (
              <Tab icon={<Icon />} iconPosition="start" label={title} {...a11yProps(0)} />
            ))}
          </Tabs>
        </div>
      </div>

      {faqData.data.map((item, index) => (
        <TabPanel title={item.title} value={value} index={index}>
          {item.questions.map((question, qIndex) => (
            <Accordion defaultExpanded={qIndex === 0} classes={{ root: 'rounded-none !shadow-none text-base !font-medium' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {question.title}
              </AccordionSummary>
              <AccordionDetails classes={{ root: 'text-sm' }}>
                {question.answer}
              </AccordionDetails>
            </Accordion>
          ))}
        </TabPanel>
      ))}
    </Box>
    </div>
  );
}

function TabPanel(props: any) {
  const { title, children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className="pl-16"
      {...other}
    >
      <h3 className="mt-0">{title}</h3>
      {value === index && (
        children
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
    classes: { root: '!min-h-8 !normal-case !text-black !text-xs !font-semibold !rounded-md !w-full !justify-start', selected: '!bg-white' }
  };
}

export default Faq;
