import React from "react";

const WishlistPage = React.lazy(() => import('../../modules/wishlist/WishlistPage'));

const WishlistConfig = {
  routes: [
    {
      path: '/wishlist',
      element: <WishlistPage />
    }
  ]
}

export default WishlistConfig;
