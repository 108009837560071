import { useMutation } from 'react-query';
import API from '../endpoints';
import { SubscribeUserRequest } from '../../models/common.models';

function subscribeUser(requestData: SubscribeUserRequest) {
  return API.subscribeUser(requestData);
}

function useSubscribeUserMutation() {
  return useMutation(subscribeUser);
}

export default useSubscribeUserMutation;
