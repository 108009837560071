import { useNavigate } from 'react-router-dom';
import styles from './LeftSide.module.scss';

const LeftSide = () => {
  const navigate = useNavigate();

  return (
    <div
      className={styles.root}
      onClick={() => navigate('/')}
    >
      Atlassian
      {/* <img src="/images/logo.png" alt="logo" className={styles.desktop} />
      <img src="/images/logo-small.png" alt="logo" className={styles.mobile} /> */}
    </div>
  );
}

export default LeftSide;
