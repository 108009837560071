import { Accordion, AccordionDetails, Button, List, ListItem, ListItemText, Switch, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";

interface Props {
  isExpanded: boolean;
  onConfirm: any;
}

const Customization = ({ isExpanded, onConfirm }: Props) => {
  const [checked, setChecked] = useState(['necessary', 'google-analytics']);
  
  useEffect(() => {
    if (localStorage.getItem('is_google_analytics_accepted') === 'false') {
      setChecked(values => values.filter(val => val !== 'google-analytics'));
    }
  }, []);

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <div className="mt-5">
      <Accordion expanded={isExpanded} classes={{
        root: '!shadow-none'
      }}>
        <div />
        <AccordionDetails classes={{ root: '!p-0' }}>
          <div>
            <List>
              <ListItem classes={{ root: '!p-0' }}>
                <ListItemText id="switch-list-label-necessary" primary="Strictly Necessary Cookies" classes={{ root: '[&>span]:!text-sm [&>span]:!font-medium' }} />
                <Tooltip title="Always active" placement="top" arrow>
                  <span>
                    <Switch
                      edge="end"
                      onChange={handleToggle('necessary')}
                      checked={checked.indexOf('necessary') !== -1}
                      disabled
                    />
                  </span>
                </Tooltip>
              </ListItem>
              <ListItem classes={{ root: '!p-0' }}>
                <ListItemText id="switch-list-label-google-analytics" primary="Google Analytics" classes={{ root: '[&>span]:!text-sm [&>span]:!font-medium' }} />
                <Switch
                  edge="end"
                  onChange={handleToggle('google-analytics')}
                  checked={checked.indexOf('google-analytics') !== -1}
                />
              </ListItem>
            </List>
            <div className="text-right mt-4">
              <Button variant="outlined" onClick={() => onConfirm(checked)} className="!normal-case !bg-lightBlue">
                Submit my choices
              </Button>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default Customization
