import i18next from "i18next";
import translationsEn from './translations/en.json';
import { I18nextProvider } from "react-i18next";
import AppContextProvider, { useAppContext } from "./context/AppContext";
import { BrowserRouter, useRoutes } from "react-router-dom";
import { Suspense } from "react";
import { initializeYup } from './config/validation.config';
import { QueryClient, QueryClientProvider } from "react-query";
import SnackbarContextProvider from "./context/SnackbarContext";
import FavoriteContextProvider from "./context/FavoriteContext";
import 'swiper/css';
import { ScrollToTop } from "../common";
import { IKContext } from "imagekitio-react";
import ReactGA from "react-ga4";
import './App.scss';
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

i18next.init({
  lng: 'en',
  resources: {
    en: {
      common: translationsEn
    }
  },
  interpolation: {
    escapeValue: false
  }
}).then(t => {
  initializeYup(t);
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 1000,
      cacheTime: 10
    },
  },
});

const initializeGoogleAnalytics = () => {
  if (localStorage.getItem('is_google_analytics_accepted') !== 'false') {
    ReactGA.initialize("G-SG3YWTHTHW");
  }
}

const App = () => {
  initializeGoogleAnalytics();

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY as string}>
      <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18next}>
          <AppContextProvider>
            <FavoriteContextProvider>
              <SnackbarContextProvider>
                <BrowserRouter>
                  <ScrollToTop></ScrollToTop>
                  <IKContext urlEndpoint="https://ik.imagekit.io/o23ci9nx7">
                    <RoutesSelector />
                  </IKContext>
                </BrowserRouter>
              </SnackbarContextProvider>
            </FavoriteContextProvider>
          </AppContextProvider>
        </I18nextProvider>
      </QueryClientProvider>
    </GoogleReCaptchaProvider>
  );
}

const RoutesSelector = () => {
  const { routes } = useAppContext();
  const elem = useRoutes(routes);

  return (
    <Suspense fallback={<div />}>
      {elem}
    </Suspense>
  )
}

export default App;
